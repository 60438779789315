import React, { Dispatch, useCallback } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import UserMenu from './components/UserMenu'
import OrganisationSelect from './components/OrganisationSelect'
import { Organisation } from '@typings/entities/Organisation'
import { DefaultItem as MenuItem } from '@mailstep/design-system/ui/Elements/DropdownMenu/types'
import { AccountData } from '@typings/entities/User'
import HelpCenter from './components/HelpCenter'
import Notifications from './components/Notifications'
import { WarningItem } from './components/Warnings'
import BrandRow from '../LeftMenu/components/BrandRow'
import { HamburgerMenu } from '../LeftMenu/components/HamburgerMenu'
import EshopSelect from './components/EshopSelect'
import CloseSettings from './components/CloseSettings'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { selectors as uiSelectors } from '@store/ui'
import { useCheckDeviceWidth } from '@components/layouts/BaseLayout/utils'

export type Props = {
  loggedAccount?: AccountData
  userMenuItems?: MenuItem[]
  helpSectionItems?: MenuItem[]
  warningItems?: WarningItem[]
  logout?: Function
  organisations?: Organisation[]
  activeLanguage?: string
  activeOrganisation: string
  onChangeOrganisation: (value: string) => void
  onLanguageChange: (value: string) => void
  notificationCount?: number
  warningsCount?: number
  notificationsLink: string
  appVersion: string
  loginLink: string
  homeLink: string
  setIsLeftMenuOpen?: Dispatch<React.SetStateAction<boolean>>
  isOnSettingsPage?: boolean
}

const StyledContainer = styled(x.div)`
  padding: 8px 10px;
  height: 52px;
  background-color: ${({ theme }) => theme.colors.white};
  color: gray1;
  display: flex;
  justify-content: space-between;
  font-family: ${th('fonts.primary')};
  z-index: 6;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray7};
  @media (min-width: 1024px) {
    padding: 8px 20px;
  }
`

const LeftSide = styled(x.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const RightSide = styled(x.div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > :nth-child(2) {
    display: none;
    @media (min-width: 1024px) {
      display: inline;
    }
  }

  & > *:not(:last-child) {
    margin-right: 16px;
    @media (min-width: 1024px) {
      margin-right: 15px;
    }
  }
`

const OrganisationSelectContainer = styled(x.div)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    margin-right: 15px;
  }
`

const Heading = ({
  homeLink,
  userMenuItems,
  helpSectionItems,
  warningItems,
  loggedAccount,
  organisations,
  onChangeOrganisation,
  onLanguageChange,
  activeLanguage = '',
  activeOrganisation,
  notificationCount,
  warningsCount,
  appVersion,
  loginLink,
  setIsLeftMenuOpen,
  isOnSettingsPage,
}: Props): JSX.Element => {
  const isSelectedOrganisation = !!activeOrganisation
  const history = useHistory()
  const cameToSettingsFromPage = useSelector(uiSelectors.cameToSettingsFromPage)
  const { isMobile } = useCheckDeviceWidth()

  const onCloseSettings = useCallback(() => {
    history.push(cameToSettingsFromPage)
  }, [cameToSettingsFromPage, history])

  const onToggleLeftMenu = useCallback(() => {
    setIsLeftMenuOpen?.((prev) => !prev)
  }, [setIsLeftMenuOpen])

  return (
    <StyledContainer>
      <LeftSide>
        <HamburgerMenu onClick={onToggleLeftMenu} />
        <BrandRow logoLink={homeLink} isCompact />
        <OrganisationSelectContainer>
          <OrganisationSelect
            activeOrganisation={activeOrganisation}
            onChangeOrganisation={onChangeOrganisation}
            organisations={organisations}
          />
        </OrganisationSelectContainer>
        <EshopSelect />
      </LeftSide>
      <RightSide>
        {isOnSettingsPage ? (
          <CloseSettings onCloseSettings={onCloseSettings} isMobile={isMobile} />
        ) : (
          <>
            <Notifications
              notificationsCount={notificationCount}
              warningsCount={warningsCount}
              warningItems={warningItems}
              disabled={!isSelectedOrganisation}
            />
            <HelpCenter menuItems={helpSectionItems} />
            <UserMenu
              menuItems={userMenuItems}
              onLanguageChange={onLanguageChange}
              activeLanguage={activeLanguage}
              appVersion={appVersion}
              loggedAccount={loggedAccount}
              loginLink={loginLink}
              activeOrganisation={activeOrganisation}
              onChangeOrganisation={onChangeOrganisation}
              organisations={organisations}
            />
          </>
        )}
      </RightSide>
    </StyledContainer>
  )
}

export default Heading
