import React, { useCallback } from 'react'
import { withReduxActions } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { ColumnDefinition, GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { FormGrid } from '@components/blocks/FormGrid'
import { ExpeditionDetailGridItem } from '@typings/entities/Expedition'
import { ProductStockApiLot } from '@typings/entities/ProductStock'
import { DateCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

type Props = {
  stockLots: ExpeditionDetailGridItem['stockLots']
  onLotSelect: (lot: ProductStockApiLot['lot']) => void
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
}

const createColumnsDefinitions: ColumnDefinition[] = [
  {
    name: 'lot',
    title: <Trans id="dataGrid.columnLot.title" message="LOT" />,
    flexBasis: 120,
    sorting: true,
    filtering: true,
  },
  {
    name: 'date',
    title: <Trans id="dataGrid.columnLotDate.title" message="Date" />,
    flexBasis: 105,
    filteringType: 'date',
    sorting: true,
    filtering: true,
    cellComponent: DateCell,
  },
  {
    name: 'available',
    title: <Trans id="dataGrid.columnQtyAvailable.title" message="QTY available" />,
    flexBasis: 120,
    sorting: true,
    filtering: true,
    filteringType: 'number',
  },
  {
    name: 'booked',
    title: <Trans id="dataGrid.columnQtyReserved.title" message="QTY reserved" />,
    flexBasis: 140,
    sorting: true,
    filtering: true,
    filteringType: 'number',
  },
  {
    name: 'quantity',
    title: <Trans id="dataGrid.columnQtyStock.title" message="QTY stock" />,
    flexBasis: 120,
    sorting: true,
    filtering: true,
    filteringType: 'number',
  },
  {
    name: 'required',
    title: <Trans id="dataGrid.columnStockRequired.title" message="Required" />,
    flexBasis: 60,
    sorting: true,
    filtering: true,
    filteringType: 'number',
  },
]

const ConnectedFormGrid = withReduxActions('StocksLots')(FormGrid)

const LotsList = ({ stockLots, onLotSelect, gridSelectors, gridActions }: Props): JSX.Element => {
  const onRowClick = useCallback((value: string, row: ProductStockApiLot): void => onLotSelect(row.lot), [onLotSelect])
  return (
    <ConnectedFormGrid
      gridSelectors={gridSelectors}
      gridActions={gridActions}
      items={stockLots}
      columnsDefinitions={createColumnsDefinitions}
      minColumnWidth={10}
      onRowClick={onRowClick}
    />
  )
}

export default LotsList
