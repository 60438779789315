import React from 'react'
import CommonGrid from '@components/blocks/CommonGrid'
import { RowProps, CommonGridProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { filterSortRowsBySettings, sliceDataBySettings } from './utils'
import { x } from '@xstyled/styled-components'

type Props = {
  items?: RowProps[]
  height?: string
} & Omit<CommonGridProps, 'rowsData' | 'totalRowsCount'>

export const FormGrid = ({ items = [], isLoading, ...rest }: Props): JSX.Element => {
  const { gridSelectors } = rest
  const filteredItems = React.useMemo(() => filterSortRowsBySettings(items, gridSelectors), [items, gridSelectors])
  const filteredItemsCount = filteredItems.length
  const pageItems = React.useMemo(() => sliceDataBySettings(filteredItems, gridSelectors), [filteredItems, gridSelectors])

  React.useEffect(() => {
    rest.gridActions?.clearSettings?.()
  }, []) // clear grid redux form previous expedition

  return (
    <x.div h="570px">
      <CommonGrid
        isLoading={isLoading !== undefined ? isLoading : false}
        rowsData={pageItems}
        totalRowsCount={filteredItemsCount}
        {...rest}
      />
    </x.div>
  )
}
