import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Form, FormikHelpers, FormikProps } from 'formik'
import ExpeditionHeader from './components/ExpeditionHeader'
import ExpeditionActions from './components/ExpeditionActions'
import { ItemsWrap, wrapperStyle } from './styles'
import OrderDetails from './components/OrderDetails'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import ShippingOptions from './components/ShippingOptions'
import { ExpeditionDetail, ExpeditedStatuses, EditableStatuses, ExpeditionServices } from '@typings/entities/Expedition'
import ExpeditionItems from './components/ExpeditionItems'
import Fieldset from '@designSystem/Fieldset'
import { Carrier, CarrierPickupPlace, CarrierService } from '@typings/entities/Carrier'
import { Country } from '@typings/entities/Country'
import { WarehouseNested } from '@typings/entities/Warehouse'
import { Partner } from '@typings/entities/Partner'
import { FormikHandleSubmit } from '@typings/Formik'
import { EshopApi } from '@typings/entities/Eshop'
import { Trans } from '@lingui/react'
import useEffectChange from '@hooks/useEffectChange'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useAcl } from '@components/blocks/Acl'
import { ProductApiRead } from '@typings/entities/Product'
import { ProductStockApi } from '@typings/entities/ProductStock'
import { GeneralErrorField } from '../components/CommonErrorField'
import getCountryOptions from '@utils/getCountryOptions'
import getEshopOptions from '@utils/getEshopOptions'
import { StockAdviceItemApi } from '@typings/entities/StockAdvice'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import {
  createWarehouseOptions,
  createCarrierOptions,
  createCarrierServicesOptions,
  createOnCarrierServiceChange,
  createOnCountryChange,
  createOnPartnerChange,
  createPartnerOptions,
  createWmsOptions,
  useIsEditable,
} from './helpers'
import HistoryTimeline from './components/HistoryTimeline'
import { Parcels } from './components/Parcels'
import { StyledSubTitle } from '@components/elements/Typography/lib'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { useExpeditionRepositionQuery } from '@hooks/apiHooks/expeditions/useExpeditionReposition'
import Tabs from '@mailstep/design-system/ui/Blocks/Tabs'
import { TabContent } from '@mailstep/design-system/ui/Blocks/Tabs/TabContent'
import { useTabs } from '@mailstep/design-system/ui/Blocks/Tabs/hooks/useTabs'
import getExpeditionServiceOptions from '@utils/getExpeditionServiceOptions'
import { tabsForCreate, tabsForUpdate } from './constants'

const StyledForm = styled(Form)`
  ${wrapperStyle}
`

export type ExternalFormProps = {
  expeditionId?: string
  warehouses: WarehouseNested[]
  eshops: EshopApi[]
  partners: Partner[]
  accounts: SelectOption[]
  carriers: Carrier[]
  carrierServices: CarrierService[]
  carrierServiceIsLoading: boolean
  pickupPlacesData: CarrierPickupPlace[]
  searchPickupPlaces: (
    text: string,
    byId: boolean,
    country: string,
    carrier: string,
    pickupType: string,
    validatePickupPlaceCountry: boolean,
  ) => Promise<SelectOption[]>
  searchExternalPickupPlace: (id: string, byId: boolean, country: string, carrierPickupPlace: string) => Promise<SelectOption[]>
  loadProducts: (fulltext: string, eshop: string) => Promise<ProductApiRead[]>
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>
  countries: Country[]
  onBack: () => void
  onInvoiceDownload: () => Promise<any>
  onCloneInvoice: () => void
  itemsGridSelectors: GridSelectorsType
  itemsGridActions: GridActionsType
  lotsGridSelectors: GridSelectorsType
  lotsGridActions: GridActionsType
  onComplaintOpen: () => void
  pairingGridSelectors: GridSelectorsType
  pairingGridActions: GridActionsType
  loadAdviceItems: (productId: string, warehouse: string, wms: string) => void
  adviceItems: StockAdviceItemApi[]
  adviceItemsIsLoading: boolean
  onOpenAdvice: (id: string) => void
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
  title: string | JSX.Element
  titleIcon: string
  organisationWithAddressValidation: boolean
  onCancel: FormikHandleSubmit
  onRollback: FormikHandleSubmit
  onConfirm: FormikHandleSubmit
  hasDuplicatePositions?: boolean
  reloadExpedition: () => void
  handleValidationSchemaChange: () => void
  expeditionServices: ExpeditionServices[]
  defaultTab?: number
}

type WrapProps = {
  setServiceFieldsMandatory?: Function
  setDifferentDeliveryAddress?: Function
  setCod?: Function
  formikHelpers: FormikHelpers
}

type Props = ExternalFormProps & WrapProps & FormikProps<ExpeditionDetail>

const emptyWarehouses: WarehouseNested[] = []
const emptyEshops: EshopApi[] = []
const emptyCarriers: Carrier[] = []
const emptyCarrierServices: CarrierService[] = []
const emptyPartners: Partner[] = []
const emptyAccounts: SelectOption[] = []
const emptyCounties: Country[] = []

const ExpeditionEditForm = ({
  expeditionId,
  handleSubmit,
  onCancel,
  onRollback,
  onConfirm,
  values,
  warehouses = emptyWarehouses,
  eshops = emptyEshops,
  carriers = emptyCarriers,
  carrierServices = emptyCarrierServices,
  carrierServiceIsLoading,
  partners = emptyPartners,
  accounts = emptyAccounts,
  pickupPlacesData,
  searchPickupPlaces,
  searchExternalPickupPlace,
  loadProducts,
  loadProductStocks,
  setServiceFieldsMandatory,
  setDifferentDeliveryAddress,
  setCod,
  isSubmitting = false,
  setFieldValue,
  onBack,
  onInvoiceDownload,
  onCloneInvoice,
  countries = emptyCounties,
  itemsGridSelectors,
  itemsGridActions,
  lotsGridSelectors,
  lotsGridActions,
  onComplaintOpen,
  pairingGridSelectors,
  pairingGridActions,
  loadAdviceItems,
  adviceItems,
  adviceItemsIsLoading,
  onOpenAdvice,
  onImportItems,
  onDownloadImportTemplate,
  title,
  titleIcon,
  organisationWithAddressValidation,
  formikHelpers,
  hasDuplicatePositions,
  reloadExpedition,
  handleValidationSchemaChange,
  expeditionServices,
  defaultTab = 0,
}: Props): JSX.Element => {
  const ability = useAcl()
  const isEditPermitted = ability.can('edit', 'Expedition')
  const canReposition = ability.can('reposition', 'Expedition')
  const isCreatingNew = !values?.status
  const lockBillingAddress = !values?.billingCountry
  const lockDeliveryAddress = !values?.deliveryCountry
  const isEditable = useIsEditable(isEditPermitted, isCreatingNew, eshops, values)
  const isExpeditedState = ExpeditedStatuses.includes(values?.status)
  const isPairingEditable = isEditPermitted && (isCreatingNew || EditableStatuses.includes(values?.status))
  const { reposition } = useExpeditionRepositionQuery()
  const countryOptions = useMemo(() => getCountryOptions(countries), [countries])
  const eshopOptions = useMemo(() => getEshopOptions(eshops), [eshops])
  const warehouseOptions = useMemo(
    () => createWarehouseOptions(warehouses, eshops, values.eshop),
    [warehouses, eshops, values.eshop],
  )

  const expeditionServiceOptions = useMemo(() => getExpeditionServiceOptions(expeditionServices), [expeditionServices])
  const wmsOptions = useMemo(() => createWmsOptions(warehouses, values.warehouse), [warehouses, values.warehouse])
  const partnersOptions = useMemo(() => createPartnerOptions(partners, values.eshop), [partners, values.eshop])
  const actualDeliveryCountry = values.differentDeliveryAddress ? values.deliveryCountry : values.billingCountry
  const carrierOptions = useMemo(() => createCarrierOptions(carriers, actualDeliveryCountry), [carriers, actualDeliveryCountry])
  const carrierServiceOptions = useMemo(
    () => createCarrierServicesOptions(carrierServices, values.carrier, actualDeliveryCountry),
    [carrierServices, values.carrier, actualDeliveryCountry],
  )
  const selectedCarrier: Carrier | null = useMemo(() => {
    return carriers.find((carrier) => carrier.id === values.carrier) || null
  }, [carriers, values.carrier])

  const validatePickupPlaceCountry = selectedCarrier?.validatePickupPlaceCountry

  const selectedCarrierService: CarrierService | null = useMemo(
    () => carrierServices.find((service) => service.id === values.carrierService) || null,
    [carrierServices, values.carrierService],
  )

  const pickupType = selectedCarrierService?.carrierPickupPlace

  const [canSelectExternalPickupPoint, setCanSelectExternalPickupPoint] = useState(false)

  const canCarrierAddressValidate: boolean = useMemo(() => {
    if (!selectedCarrier) return false // if no carrier selected we act as it validation is no available
    const carrierExcludedCountries = selectedCarrier?.excludedExpeditionAddressValidations || []
    if (!carrierExcludedCountries.length) return true // no excluded country = can regardless of country
    return !!(actualDeliveryCountry && !carrierExcludedCountries.includes(actualDeliveryCountry))
  }, [carriers, values.carrier, actualDeliveryCountry])

  const handleCancel = useCallback((): void => {
    formikHelpers.setSubmitting(true)
    onCancel(values, formikHelpers)
  }, [onCancel, values, formikHelpers])

  const handleRollback = useCallback(() => {
    formikHelpers.setSubmitting(true)
    onRollback(values, formikHelpers)
  }, [onRollback, values, formikHelpers])

  const handleConfirm = useCallback(() => {
    formikHelpers.setSubmitting(true)
    onConfirm(values, formikHelpers)
  }, [onConfirm, values, formikHelpers])

  const handleItemsReposition = useCallback(async () => {
    const { error } = await reposition(expeditionId)

    if (!error) {
      reloadExpedition()
    }
  }, [reposition, expeditionId, reloadExpedition])

  const handleSearchPickupPlaces = useCallback(
    (text: string, byId: boolean): Promise<SelectOption[]> => {
      return searchPickupPlaces(
        text,
        byId,
        actualDeliveryCountry || '',
        values.carrier || '',
        pickupType || '',
        validatePickupPlaceCountry,
      )
    },
    [searchPickupPlaces, actualDeliveryCountry, values.carrier, pickupType],
  )

  const handleSearchExternalPickupPlaces = useCallback(
    (text: string, byId: boolean): Promise<SelectOption[]> => {
      return searchExternalPickupPlace(text, byId, actualDeliveryCountry || '', values?.carrierPickupPlace || '')
    },
    [actualDeliveryCountry, searchExternalPickupPlace, values.carrierPickupPlace],
  )

  const handleLoadProducts = useCallback(
    (fulltext: string) => {
      return loadProducts(fulltext, values.eshop)
    },
    [loadProducts, values.eshop],
  )

  const onCountryChange = useMemo(
    () => createOnCountryChange(countries, setFieldValue, values.differentDeliveryAddress, values.partner),
    [countries, setFieldValue, values.differentDeliveryAddress, values.partner],
  )
  const onPartnerChange = useMemo(
    () => createOnPartnerChange(partners, countries, setFieldValue),
    [partners, countries, setFieldValue],
  )
  const onCarrierServiceChange = useMemo(
    () => createOnCarrierServiceChange(carrierServices, setFieldValue, setServiceFieldsMandatory),
    [carrierServices, setFieldValue, setServiceFieldsMandatory],
  )

  useEffectChange(() => {
    const selectedEshop = eshops.find((eshop) => eshop.id == values.eshop)
    if (!selectedEshop?.warehouses?.find((warehouse) => warehouse.id == values.warehouse)) {
      // previously selected warehouse not found in newly selected eshop
      const resetWarehouse = selectedEshop?.defaultWarehouse?.id || ''
      setFieldValue('warehouse', resetWarehouse, false)
    }
    // partner is bound to single eshop so we can always reset it
    setFieldValue('partner', '', false)
  }, [setFieldValue, values.eshop])

  useEffectChange(() => {
    const selectedWarehouse = warehouses.find((warehouse) => warehouse.id === values.warehouse)
    if (!selectedWarehouse?.wmses.find((wms) => wms.id === values.wms)) {
      // previously selected wms not found in newly selected warehouse
      const resetWms = selectedWarehouse?.defaultWms?.id || ''
      setFieldValue('wms', resetWms, false)
    }
  }, [setFieldValue, values.warehouse])

  useEffectChange(() => {
    if (values.externalCarrierPickupPlace !== null) setFieldValue('externalCarrierPickupPlace', '', false)
    if (values.carrierPickupPlace !== null) setFieldValue('carrierPickupPlace', '', false)
    if (values.carrierService !== '') setFieldValue('carrierService', '', false)
  }, [setFieldValue, values.carrier])

  useEffectChange(() => {
    if (!carrierOptions.find((option) => option.value == values.carrier)) {
      setFieldValue('carrier', '', false)
    }
  }, [setFieldValue, actualDeliveryCountry])

  useEffect(() => {
    if (setDifferentDeliveryAddress) setDifferentDeliveryAddress(values.differentDeliveryAddress)
  }, [setDifferentDeliveryAddress, values.differentDeliveryAddress])

  useEffect(() => {
    if (setCod) setCod(values.cod)
  }, [setCod, values.cod])

  useEffect(() => {
    if (values.carrierPickupPlace) {
      const hasPoints =
        (pickupPlacesData || []).find((pickupPlace) => pickupPlace.id === values.carrierPickupPlace)?.type ===
        'carrier_service_with_points'

      setCanSelectExternalPickupPoint(hasPoints)

      if (!hasPoints && pickupPlacesData?.length && values.externalCarrierPickupPlace) {
        if (values.externalCarrierPickupPlace !== null) setFieldValue('externalCarrierPickupPlace', '', false)
      }
    } else {
      setCanSelectExternalPickupPoint(false)

      if (values.externalCarrierPickupPlace) {
        setFieldValue('externalCarrierPickupPlace', '', false)
      }
    }
  }, [values.carrierPickupPlace, values.externalCarrierPickupPlace, pickupPlacesData, setFieldValue])

  const tabs = useMemo(
    () =>
      isCreatingNew
        ? tabsForCreate()
        : tabsForUpdate({ packagesCount: values?.parcels?.length, adviceItemsCount: values?.items?.length, defaultTab }),
    [isCreatingNew, values?.parcels?.length, values?.items?.length, defaultTab],
  )
  const { activeTab, onTabSwitch } = useTabs(tabs)

  useEffect(() => {
    handleValidationSchemaChange(values.carrierPickupPlace)
  }, [handleValidationSchemaChange, values.carrierPickupPlace])

  return (
    <Modal
      hasFooter={false}
      title={title}
      titleIcon={titleIcon}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
      width="wide"
      isLoading={isSubmitting}
      onCancel={onBack}
    >
      <StyledForm onSubmit={handleSubmit}>
        <ExpeditionHeader status={values.status} orderNumber={values.orderNumber} isCreatingNew={isCreatingNew} />

        <Tabs mb="24px" tabsDefinition={tabs} activeTab={activeTab} onTabSwitch={onTabSwitch} />
        <TabContent activeTab={activeTab} value={0}>
          <>
            <OrderDetails
              eshops={eshopOptions}
              warehouses={warehouseOptions}
              partners={partnersOptions}
              wmses={wmsOptions}
              deliveryPdfFile={values.deliveryPdfFile}
              partner={values.partner}
              differentDeliveryAddress={values.differentDeliveryAddress}
              hasEshop={!!values.eshop}
              hasWarehouse={!!values.warehouse}
              countries={countryOptions}
              onCountryChange={onCountryChange}
              onPartnerChange={onPartnerChange}
              setFieldValue={setFieldValue}
              onInvoiceDownload={onInvoiceDownload}
              isEditable={isEditable}
              isCreatingNew={isCreatingNew}
              lockBillingAddress={lockBillingAddress}
              lockDeliveryAddress={lockDeliveryAddress}
              expeditionServiceOptions={expeditionServiceOptions}
            />
            <ItemsWrap>
              <ExpeditionItems
                isEditable={isEditable}
                isPairingEditable={isPairingEditable}
                loadProducts={handleLoadProducts}
                setFieldValue={setFieldValue}
                warehouse={values.warehouse}
                wms={values.wms}
                bookedData={values.bookedData}
                virtualItems={values.virtualItems}
                loadProductStocks={loadProductStocks}
                itemsGridSelectors={itemsGridSelectors}
                itemsGridActions={itemsGridActions}
                lotsGridSelectors={lotsGridSelectors}
                lotsGridActions={lotsGridActions}
                pairingGridSelectors={pairingGridSelectors}
                pairingGridActions={pairingGridActions}
                isExpedited={isExpeditedState}
                loadAdviceItems={loadAdviceItems}
                adviceItems={adviceItems}
                adviceItemsIsLoading={adviceItemsIsLoading}
                onOpenAdvice={onOpenAdvice}
                onImportItems={onImportItems}
                onDownloadImportTemplate={onDownloadImportTemplate}
                handleItemsReposition={handleItemsReposition}
                hasDuplicatePositions={hasDuplicatePositions}
                canReposition={canReposition}
              />
            </ItemsWrap>
            <Fieldset disabled={!isEditable}>
              <StyledSubTitle>
                <Trans id="form.shippingOptions.heading" message="Shipping options" />
              </StyledSubTitle>
              <ShippingOptions
                carriers={carrierOptions}
                carrierServices={carrierServiceOptions}
                carrierServiceIsLoading={carrierServiceIsLoading}
                searchPickupPlaces={handleSearchPickupPlaces}
                searchExternalPickupPlaces={handleSearchExternalPickupPlaces}
                hasDeliveryCountry={!!actualDeliveryCountry}
                hasCarrier={!!values.carrier}
                hasCarrierService={!!values.carrierService}
                hasCarrierPickupPlace={!!values.carrierPickupPlace}
                onCarrierServiceChange={onCarrierServiceChange}
                canSelectExternalPickupPoint={canSelectExternalPickupPoint}
                cod={values.cod}
                currency={values.currency}
                codCurrency={values.codCurrency}
                foreignPrice={values.foreignPrice}
                isEditable={isEditable}
                isWithoutPickupPlace={selectedCarrierService?.pickupPlaceIgnore}
                canOrganisationAddressValidate={organisationWithAddressValidation}
                canCarrierAddressValidate={canCarrierAddressValidate}
              />
            </Fieldset>
          </>
        </TabContent>

        <TabContent activeTab={activeTab} value={1}>
          <Parcels expeditionId={expeditionId} />
        </TabContent>

        <TabContent activeTab={activeTab} value={2}>
          <HistoryTimeline audits={values.audits} deliveredAt={values?.deliveredAt} accounts={accounts} />
        </TabContent>

        <ExpeditionActions
          isLoading={isSubmitting}
          status={values.status}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onRollback={handleRollback}
          onConfirm={handleConfirm}
          onComplaintOpen={onComplaintOpen}
          onCloneInvoice={onCloneInvoice}
          isEditable={isEditable}
          isEditPermitted={isEditPermitted}
          isCreatingNew={isCreatingNew}
          ability={ability}
        />

        {/* Notify user of form errors after clicking buttons */}
        <x.div display="flex" justifyContent="flex-end">
          <GeneralErrorField />
        </x.div>
      </StyledForm>
    </Modal>
  )
}

export default ExpeditionEditForm
