import React, { useMemo } from 'react'
import styled from '@xstyled/styled-components'
import { Field } from 'formik'
import { Trans } from '@lingui/react'
import HighlightBox from '@components/elements/HighlightBoxReworked'
import InfoHeader from '@components/blocks/InfoHeader'
import InputField from '@components/forms/components/InputField'
import { InitialCommonErrorField } from '@components/forms/components/CommonErrorField'
import { ExpeditionDetail, ExpeditionDetailStatusTrans, FlagStatusToAppearance } from '@typings/entities/Expedition'
import { x } from '@xstyled/styled-components'
import { th } from '@xstyled/system'

interface HeaderType {
  status?: ExpeditionDetail['status']
  orderNumber: ExpeditionDetail['orderNumber']
  isCreatingNew: boolean
}

const StyledContainer = styled(x.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
  border-bottom: 1px solid ${th('colors.lightGray3')};
  padding-bottom: 3;

  @media (min-width: 1024px) {
    border-bottom: none;
    flex-direction: row;
    padding-bottom: 0;
  }
`

const ExpeditionHeader = ({ status, orderNumber, isCreatingNew }: HeaderType): JSX.Element => {
  const infoHeaderItems = useMemo(() => {
    const statusField = {
      label: <Trans id="form.status.label" message="Status" />,
      component: status && (
        <HighlightBox appearance={FlagStatusToAppearance[status]} size="default">
          {ExpeditionDetailStatusTrans[status]?.()}
        </HighlightBox>
      ),
      isSmall: true,
    }
    return [
      {
        label: <Trans id="form.orderNumber.label" message="Order number" />,
        component: isCreatingNew ? <Field name="orderNumber" component={InputField} /> : orderNumber,
      },
      ...(status ? [statusField] : []),
    ]
  }, [isCreatingNew, orderNumber, status])

  return (
    <>
      <StyledContainer>
        <x.div w="50%" minWidth="300px">
          <InfoHeader items={infoHeaderItems} />
        </x.div>
      </StyledContainer>

      <InitialCommonErrorField />
    </>
  )
}

export default React.memo(ExpeditionHeader)
