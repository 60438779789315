import { Formik, FormikProps } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import ExpeditionEditForm, { ExternalFormProps } from '@components/forms/ExpeditionEditForm/ExpeditionEditForm'
import getOr from 'lodash/fp/getOr'
import { FormikHandleSubmit, getFormikHelpers } from '@typings/Formik'
import { createValidationSchema } from './validation'
import { ExpeditionDetail, ExpeditionDetailError, ExpeditionDetailTouched } from '@typings/entities/Expedition'

type Props = ExternalFormProps & {
  expeditionId?: string
  initialValues?: Partial<ExpeditionDetail>
  initialErrors?: ExpeditionDetailError
  initialTouched?: ExpeditionDetailTouched
  onSave: FormikHandleSubmit<ExpeditionDetail>
  onSend: FormikHandleSubmit<ExpeditionDetail>
  onSaveAndSend: FormikHandleSubmit<ExpeditionDetail>
  hasDuplicatePositions?: boolean
  reloadExpedition: () => void
}

const initialValuesEmpty = {}

const ExpeditionEditFormFormik = ({
  expeditionId,
  initialValues = initialValuesEmpty,
  initialErrors,
  initialTouched: initialTouchedProp,
  onSave,
  onSend,
  onSaveAndSend,
  ...formProps
}: Props): JSX.Element => {
  const [differentDeliveryAddress, setDifferentDeliveryAddress] = useState(
    getOr(false, 'differentDeliveryAddress', initialValues),
  )

  const [cod, setCod] = useState(getOr(false, 'cod', initialValues))

  const [serviceFieldsMandatory, setServiceFieldsMandatory] = useState(() => {
    const service = formProps.carrierServices?.find((service) => service.id === initialValues.carrierService)
    const pickupPlace = formProps.pickupPlacesData?.find((pickupPlace) => pickupPlace.id === initialValues.carrierPickupPlace)

    return {
      pickupPlace: !!service?.pickupPlaceMandatory,
      externalCarrierPickupPlace: pickupPlace?.type === 'carrier_service_with_points',
      email: !!service?.emailMandatory,
      phone: !!service?.phoneMandatory,
      name: !!service?.nameMandatory,
    }
  })

  const handleValidationSchemaChange = useCallback(
    (carrierPickupPlace = null) => {
      const pickupPlace = formProps.pickupPlacesData?.find(
        (place) => place.id === (carrierPickupPlace || initialValues.carrierPickupPlace),
      )

      const mandatory = {
        ...serviceFieldsMandatory,
        externalCarrierPickupPlace: pickupPlace?.type === 'carrier_service_with_points',
      }

      setServiceFieldsMandatory(mandatory)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formProps.pickupPlacesData, initialValues.carrierPickupPlace],
  )

  useEffect(() => {
    handleValidationSchemaChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formProps.pickupPlacesData, initialValues.carrierPickupPlace])

  const handleSubmit = useCallback(
    ({ button, ...values }, formikHelpers) => {
      switch (button) {
        case 'save':
          onSave(values, formikHelpers)
          break
        case 'send':
          onSend(values, formikHelpers)
          break
        case 'saveAndSend':
          onSaveAndSend(values, formikHelpers)
          break
      }
    },
    [onSave, onSend, onSaveAndSend],
  )

  const initialTouched = React.useMemo(() => {
    const initialTouched = initialTouchedProp || {}
    initialTouched.items = (initialValues?.items || []).map(
      (item, index) => {
        return { bookAdviceTotal: true, ...(initialTouched.items?.[index] || {}) }
      },
      [initialTouchedProp],
    )
    return initialTouched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTouchedProp])

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      validationSchema={createValidationSchema(differentDeliveryAddress, cod, serviceFieldsMandatory)}
      validateOnMount
      enableReinitialize
    >
      {(formikProps: FormikProps<ExpeditionDetail>): JSX.Element => {
        return (
          <ExpeditionEditForm
            expeditionId={expeditionId}
            {...formikProps}
            {...formProps}
            formikHelpers={getFormikHelpers(formikProps)}
            setServiceFieldsMandatory={setServiceFieldsMandatory}
            setDifferentDeliveryAddress={setDifferentDeliveryAddress}
            setCod={setCod}
            handleValidationSchemaChange={handleValidationSchemaChange}
          />
        )
      }}
    </Formik>
  )
}

export default ExpeditionEditFormFormik
