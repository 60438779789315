import React, { MutableRefObject, useMemo } from 'react'
import { Item } from '../types'
import { ItemLinkWrap, TooltipWrap, TooltipItemLabel } from '../styles'
import { Popper } from 'react-popper'
import { Portal } from '@mailstep/design-system/ui/Elements/Portal'
import { H6 } from '@mailstep/design-system/ui/Elements/Typography'
import styled from 'styled-components'

const TooltipItem = ({ title, link = '', lightMode }: Item): JSX.Element => {
  const linkProps = useMemo(() => {
    return link.startsWith('https://') ? { to: { pathname: link }, target: '_blank' } : { to: link }
  }, [link])

  return (
    <ItemLinkWrap {...linkProps} exact $lightMode={lightMode}>
      <TooltipItemLabel>
        <H6 variant="medium">{title}</H6>
      </TooltipItemLabel>
    </ItemLinkWrap>
  )
}

type Props = {
  itemsOpen?: number
  childRef: MutableRefObject<null>
  parentRef: MutableRefObject<null>
  items: Item[] | undefined
  isLeftMenuOpen?: boolean
  onCloseLeftMenu?: () => void
  lightMode?: boolean
}
const PopoverWrapper = styled.div<{ isLeftMenuOpen?: boolean }>`
  z-index: 31;
  display: ${({ isLeftMenuOpen }) => (isLeftMenuOpen ? 'block' : 'none')};
  @media (min-width: 1024px) {
    display: block;
  }
`

const SubitemTooltip = ({ items, childRef, parentRef, isLeftMenuOpen, onCloseLeftMenu, lightMode }: Props): JSX.Element => {
  return (
    <Portal>
      <Popper innerRef={childRef} referenceElement={parentRef.current} placement="right-start">
        {({ ref, style }): JSX.Element => (
          <PopoverWrapper ref={ref} style={style} onClick={onCloseLeftMenu} isLeftMenuOpen={isLeftMenuOpen}>
            <TooltipWrap $lightMode={lightMode}>
              {items?.map((item, index) => (
                <TooltipItem key={`${index}`} {...item} lightMode={lightMode} />
              ))}
            </TooltipWrap>
          </PopoverWrapper>
        )}
      </Popper>
    </Portal>
  )
}

export default SubitemTooltip
