/* eslint-disable react/display-name */
import React from 'react'
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import LotSelectCell from './LotSelect'
import { ColumnDefinition, ActionColumn, RowValueType, DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { ExpeditionDetailGridItem } from '@typings/entities/Expedition'
import ReservationStatusCell, { getReservationStatusOptions } from '@components/elements/gridCells/ReservationStatus'
import ConnectedInternalSkuCell from '@containers/ConnectedInternalSkuCell'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { isMetaLot } from '@utils/expedition'
import { IconButtonInCell, RemoveCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'

const RemoveComponent = (props: DataCellProps<ExpeditionDetailGridItem>): JSX.Element => {
  if (props.rowData.product.type === 'virtual') {
    return <Trans id="dataGrid.columnLot.NA" message="N/A" />
  }

  return <RemoveCell {...props} />
}

const PairComponent = ({ rowData, onRowAction }: DataCellProps<ExpeditionDetailGridItem>): JSX.Element => {
  const onPairClick = React.useCallback(() => {
    if (onRowAction) onRowAction(rowData.id, 'pairAdvice', rowData)
  }, [rowData, onRowAction])

  if (rowData.product.type === 'virtual' || (rowData.product.workAroundLot && !isMetaLot(rowData.lot))) {
    return <Trans id="dataGrid.columnLot.NA" message="N/A" />
  }

  return <IconButtonInCell onClick={onPairClick} icon="link" />
}

export const createActionColumnDefinition = (stocksReady: boolean): ActionColumn => ({
  actionOptions: [
    {
      value: 'reserve',
      label: t({ id: 'dataGrid.actionOptions.reserve', message: 'Reserve' }),
      isEnabled: (selectedRows): boolean => selectedRows.length > 0 && stocksReady,
    },
    {
      value: 'cancelReservation',
      label: t({ id: 'dataGrid.actionOptions.cancelReservation', message: 'Cancel Reservation' }),
      isEnabled: (selectedRows): boolean => selectedRows.length > 0 && stocksReady,
    },
    {
      value: 'delete',
      label: t({ id: 'dataGrid.actionOptions.delete', message: 'Delete' }),
      isEnabled: (selectedRows): boolean => selectedRows.length > 0 && stocksReady,
    },
  ],
  flexBasis: 40,
  addRowNumbers: true,
})

export const createColumnDefinitions = (
  isEditable: boolean,
  isPairingEditable: boolean,
  isExpedited: boolean,
  getIndex: (item: ExpeditionDetailGridItem) => number,
): ColumnDefinition[] => {
  const columns: ColumnDefinition[] = [
    {
      name: 'reservationStatus',
      title: <Trans id="dataGrid.columnReservationStatus.title" message="Reservation status" />,
      filtering: true,
      flexBasis: 140,
      filterOptions: getReservationStatusOptions(true),
      cellComponent: ReservationStatusCell,
    },
    {
      name: 'product.productSku',
      title: <Trans id="dataGrid.columnProductSku.title" message="Product SKU" />,
      sorting: true,
      filtering: true,
      flexBasis: 120,
    },
    {
      name: 'product.internalSku',
      cellComponent: ConnectedInternalSkuCell,
      title: <Trans id="dataGrid.columnInternalSku.title" message="Internal SKU" />,
      sorting: true,
      filtering: true,
      flexBasis: 120,
    },
    {
      name: 'product.name',
      title: <Trans id="dataGrid.columnProductName.title" message="Product name" />,
      sorting: true,
      filtering: true,
      flexBasis: 160,
    },
    {
      name: 'lot',
      title: <Trans id="dataGrid.columnLot.title" message="LOT" />,
      cellComponent: LotSelectCell,
      sorting: true,
      filtering: true,
      flexBasis: 120,
      fixedSize: isEditable,
      passProps: { isEditable, getIndex }, // little  hacks to pass extra values in render component
    },
    {
      name: 'productValue',
      title: <Trans id="dataGrid.productValue.title" message="Product value" />,
      flexBasis: 120,
      filtering: true,
      sorting: true,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        return (
          <Field
            name={`items[${getIndex(row)}][productValue]`}
            component={InputField}
            appearance="grid"
            errorAppearance="tooltip"
          />
        )
      },
    },
    {
      name: 'productValueCurrency',
      title: <Trans id="dataGrid.productValueCurrency.title" message="Product value currency" />,
      flexBasis: 160,
      filtering: true,
      sorting: true,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        return (
          <Field
            name={`items[${getIndex(row)}][productValueCurrency]`}
            component={InputField}
            appearance="grid"
            errorAppearance="tooltip"
          />
        )
      },
    },
    {
      name: 'ref1',
      title: <Trans id="dataGrid.columnRef1.title" message="Ref 1" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        return isEditable && row.product.type !== 'virtual' ? (
          <Field name={`items[${getIndex(row)}][ref1]`} component={InputField} appearance="grid" />
        ) : (
          <span className="tooLongText">{row.ref1}</span>
        )
      },
      sorting: true,
      filtering: true,
      flexBasis: 100,
      fixedSize: isEditable,
    },
    {
      name: 'ref2',
      title: <Trans id="dataGrid.columnRef2.title" message="Ref 2" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        return isEditable && row.product.type !== 'virtual' ? (
          <Field name={`items[${getIndex(row)}][ref2]`} component={InputField} appearance="grid" />
        ) : (
          <span className="tooLongText">{row.ref2}</span>
        )
      },
      sorting: true,
      filtering: true,
      flexBasis: 100,
      fixedSize: isEditable,
    },
    {
      name: 'ref3',
      title: <Trans id="dataGrid.columnRef3.title" message="Ref 3" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        return isEditable && row.product.type !== 'virtual' ? (
          <Field name={`items[${getIndex(row)}][ref3]`} component={InputField} appearance="grid" />
        ) : (
          <span className="tooLongText">{row.ref3}</span>
        )
      },
      sorting: true,
      filtering: true,
      flexBasis: 100,
      fixedSize: isEditable,
    },
    {
      name: 'product.referenceNumbers',
      title: <Trans id="dataGrid.columnReferenceNumbers.title" message="Reference numbers" />,
      sorting: true,
      filtering: true,
      flexBasis: 160,
    },
    {
      name: 'quantity',
      title: <Trans id="dataGrid.columnQty.title" message="Quantity" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        return isEditable && row.product.type !== 'virtual' ? (
          <Field
            name={`items[${getIndex(row)}][quantity]`}
            type="number"
            component={InputField}
            min={1}
            errorAppearance="tooltip"
            appearance="grid"
          />
        ) : (
          row.quantity
        )
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      fixedSize: isEditable,
      flexBasis: 80,
      flexGrow: 0,
    },
    {
      name: 'availableTotal',
      title: <Trans id="dataGrid.columnAvailableTotal.title" message="Available total" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        if (row.product.type === 'virtual') {
          return <Trans id="dataGrid.columnLot.NA" message="N/A" />
        }

        if (row.stocksState === 'notReady') {
          return '?'
        } else if (row.stocksState === 'loading') {
          return <Spinner variant="sm" />
        }
        return row.availableTotal
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 140,
      flexGrow: 0,
    },
    {
      name: 'availableStock',
      title: <Trans id="dataGrid.columnAvailableQty.title" message="Available QTY" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        if (row.product.type === 'virtual') {
          return <Trans id="dataGrid.columnLot.NA" message="N/A" />
        }

        if (row.stocksState === 'notReady') {
          return '?'
        } else if (row.stocksState === 'loading') {
          return <Spinner variant="sm" />
        }
        return row.availableStock
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 140,
      flexGrow: 0,
    },
    {
      name: 'book',
      title: <Trans id="dataGrid.columnBook.title" message="Book" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        if (row.product.type === 'virtual') {
          return <Trans id="dataGrid.columnLot.NA" message="N/A" />
        }

        return isEditable ? (
          <Field
            name={`items[${getIndex(row)}][book]`}
            type="number"
            component={InputField}
            min={0}
            max={row.quantity}
            errorAppearance="tooltip"
            appearance="grid"
          />
        ) : (
          row.book
        )
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 80,
      fixedSize: isEditable,
      flexGrow: 0,
    },
    {
      name: 'booked',
      title: <Trans id="dataGrid.columnBooked.title" message="Booked" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        if (row.product.type === 'virtual') {
          return <Trans id="dataGrid.columnLot.NA" message="N/A" />
        }

        if (row.stocksState === 'loading') {
          return <Spinner variant="sm" />
        }
        return row.booked || 0
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 60,
      flexGrow: 0,
    },
  ]
  if (getRuntimeConfig('FRONTEND__SHOW_PAIRING')) {
    columns.push(
      {
        name: 'bookAdviceTotal',
        title: <Trans id="dataGrid.columnPair.title" message="Pair" />,
        sorting: true,
        filtering: true,
        filteringType: 'number',
        flexBasis: 80,
        flexGrow: 0,
        fixedSize: isEditable,
        formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
          if (row.product.type === 'virtual' || (row.product.workAroundLot && !isMetaLot(row.lot))) {
            return <Trans id="dataGrid.columnLot.NA" message="N/A" />
          }

          return isEditable ? (
            <Field
              name={`items[${getIndex(row)}][bookAdviceTotal]`}
              type="number"
              component={InputField}
              errorAppearance="tooltip"
              disabled
              appearance="grid"
            />
          ) : (
            row.bookAdviceTotal
          )
        },
      },
      {
        name: 'bookedAdviceTotal',
        title: <Trans id="dataGrid.columnPaired.title" message="Paired" />,
        sorting: true,
        filtering: true,
        filteringType: 'number',
        formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
          if (row.product.type === 'virtual') {
            return <Trans id="dataGrid.columnLot.NA" message="N/A" />
          }

          return row.bookedAdviceTotal
        },
        flexBasis: 90,
        flexGrow: 0,
      },
    )
  }
  if (isExpedited) {
    columns.push({
      name: 'expedited',
      title: <Trans id="dataGrid.columnExpedited.title" message="Expedited" />,
      formatRowValue: (row: ExpeditionDetailGridItem): RowValueType => {
        if (row.product.type === 'virtual') {
          return <Trans id="dataGrid.columnLot.NA" message="N/A" />
        }

        return row.expedited
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 60,
      flexGrow: 0,
    })
  }
  if (getRuntimeConfig('FRONTEND__SHOW_PAIRING') && isPairingEditable) {
    columns.push({
      name: 'buttons2',
      title: '',
      cellComponent: PairComponent,
      alwaysOn: true,
      flexBasis: 60,
      flexShrink: 0,
      flexGrow: 0,
    })
  }
  if (isEditable) {
    columns.push({
      name: 'buttons',
      title: '',
      cellComponent: RemoveComponent,
      alwaysOn: true,
      flexBasis: 80,
      flexShrink: 0,
      flexGrow: 0,
    })
  }
  return columns
}
