import styled, { x } from '@xstyled/styled-components'
import Button from '@mailstep/design-system/ui/Elements/Button'
import TextAreaField from '@components/forms/components/TextAreaField'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3;
`

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  & > :not(:last-child) {
    margin-right: 2;
  }
`

export const FlexEndButton = styled(Button)`
  align-self: flex-end;
`

export const HighTextAreaField = styled(TextAreaField)`
  min-height: 155px;
  resize: none;
`

export const WhiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 944px;
  border-radius: 4px;
  padding: 32px 20px;
  background: white;
  box-shadow: gridShadow;
  margin-bottom: 20px;
`

export const ButtonFlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 52px;
`

export const ResponsiveButton = styled(Button)`
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`

export const ResponsiveRowWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 2;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    & > :not(:last-child) {
      margin-right: 3;
      margin-bottom: 0;
    }
  }
`

export const ResponsiveSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`

export const ButtonsStripContainer = styled.div`
  margin-bottom: 1;
  margin-top: 1;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    margin-left: 2;
  }
`

export const SwitchFieldContainer = styled.div`
  width: 100%;
  & :not(:last-child) {
    margin-right: 2;
  }

  @media (min-width: 768px) {
    width: 37%;
  }
`
