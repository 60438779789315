import React from 'react'
import styled from '@xstyled/styled-components'
import FooterRow from './components/Footer'
import MenuItem from './MenuItem'
import { LeftMenuProvider } from './context/LeftMenuContext'
import { Item } from './types'
import { withAcl } from '@components/blocks/Acl'
import { HamburgerMenu } from './components/HamburgerMenu'
import { useClickOutside } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useClickOutside'
import { createRgba } from '@mailstep/design-system/ui/utils/CreateRgba/createRgba'
import { th } from '@xstyled/styled-components'

export const TopLevelWrap = styled.div<{ isCompact: boolean; isLeftMenuOpen?: boolean; lightMode?: boolean }>`
  background-color: ${({ lightMode }) => (lightMode ? 'white' : th('colors.blue3'))};
  border-right: 1px solid ${th('colors.lightGray6')};
  height: -webkit-fill-available;
  transition: width 0.5s ease;
  width: ${({ isCompact }) => (isCompact ? '75px' : '255px')};

  z-index: 7;
  position: absolute;
  top: ${({ isLeftMenuOpen }) => (isLeftMenuOpen ? '0' : undefined)};
  height: ${({ isLeftMenuOpen }) => (isLeftMenuOpen ? '100%' : 'calc(100% - 52px)')};

  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    top: inherit;
    z-index: 2;
  }
`

export const ScrollWrap = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const BodyWrap = styled.div<{ isCompact: boolean }>`
  width: 100%;
  text-align: center;
  padding: ${({ isCompact }) => (isCompact ? '24px 9px' : '24px 22px')};
  height: calc(100% - 52px);
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 6;
  height: 100%;
  width: 100%;
  background-color: ${createRgba('blue2', 0.6)};
  @media (min-width: 1024px) {
    display: none;
  }
`

type Props = {
  menuItems: Item[]
  isCompact: boolean
  setIsCompact?: (compact: boolean) => void
  isLeftMenuOpen?: boolean
  onCloseLeftMenu?: () => void
  variant?: 'dark' | 'light'
  children?: React.ReactNode
}

const LeftMenu = ({
  menuItems,
  isCompact,
  setIsCompact,
  isLeftMenuOpen,
  onCloseLeftMenu,
  variant = 'dark',
  children,
}: Props): JSX.Element => {
  const lightMode = variant === 'light'
  const pickerRef = useClickOutside({ onClose: onCloseLeftMenu })

  return (
    <LeftMenuProvider>
      <TopLevelWrap isCompact={isCompact} isLeftMenuOpen={isLeftMenuOpen} ref={pickerRef} lightMode={lightMode}>
        <HamburgerMenu isLeftMenuOpen={isLeftMenuOpen} onClick={onCloseLeftMenu} />

        <ScrollWrap>
          <BodyWrap isCompact={isCompact}>
            {menuItems.map((item: Item, index: number) => (
              <MenuItem
                key={`${item.id}-${index}`}
                isCompact={isCompact}
                isLeftMenuOpen={isLeftMenuOpen}
                onCloseLeftMenu={onCloseLeftMenu}
                lightMode={lightMode}
                separator={!!item.separator}
                {...item}
              />
            ))}
            {children}
          </BodyWrap>
        </ScrollWrap>
        <FooterRow isCompact={isCompact} setIsCompact={setIsCompact} lightMode={lightMode} />
      </TopLevelWrap>
      <Overlay />
    </LeftMenuProvider>
  )
}

export default withAcl(LeftMenu)
