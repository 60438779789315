import React from 'react'
import { t } from '@lingui/macro'
import { ColumnDefinition } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { DateTimeCell, LinkInCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

export const columnsDefinitions = (): ColumnDefinition[] => [
  {
    name: 'createdAt',
    title: t({ id: 'dataGrid.columnCreatedAt.title', message: 'Created' }),
    flexBasis: 120,
    fixedSize: true,
    sorting: true,
    cellComponent: DateTimeCell,
  },
  {
    name: 'extCarrierTrackingNumber',
    title: t({ id: 'dataGrid.columnExtCarrierTrackingNumber.title', message: 'Ext carrier tracking number' }),
    flexBasis: 190,
    filtering: true,
  },
  {
    name: 'extPackageNumber',
    title: t({ id: 'dataGrid.columnExtPackageNumber.title', message: 'Ext package number' }),
    flexBasis: 190,
    filtering: true,
  },
  {
    name: 'extPackageTrackingNumber',
    title: t({ id: 'dataGrid.columnExtPackageTrackingNumber.title', message: 'Ext package tracking number' }),
    flexBasis: 190,
    filtering: true,
  },
  {
    name: 'trackingUrl',
    title: t({ id: 'dataGrid.columnTrackingUrl.title', message: 'Tracking url' }),
    flexBasis: 720,
    filtering: true,
    formatRowValue: ({ trackingUrl }) => {
      return (
        <LinkInCell variant="medium" to={{ pathname: trackingUrl }} target="_blank">
          {trackingUrl}
        </LinkInCell>
      )
    },
  },
]
