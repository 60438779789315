import styled, { x } from '@xstyled/styled-components'

export const Row = styled(x.div)<{ $flexCol?: boolean }>`
  display: flex;
  flex-direction: ${({ $flexCol }): string => ($flexCol ? 'column' : 'row')};
  justify-content: ${({ $flexCol }): string => ($flexCol ? 'flex-start' : 'space-between')};
  align-items: flex-start;
  padding: 24px;
  @media (min-width: 1024px) {
    padding: 0 0 24px 0;
  }
`

export const VerticalSpace = styled.div`
  width: 100%;
  height: 25px;

  @media (min-width: 1800px) {
    height: 16px;
  }
`

export const HeadingWrapper = styled(x.div)<{ variant?: string }>`
  display: flex;
  flex-direction: ${({ variant }): string => (variant ? 'column' : 'row')};
  align-items: ${({ variant }): string => (variant ? 'flex-start' : 'center')};
  line-height: 25px;
  color: typoPrimary;
  width: 100%;
  z-index: 4;
`
