import React, { FC } from 'react'
import { Parcel } from '@typings/entities/Parcel'
import { columnsDefinitions } from './columnsDefinitions'
import CommonGrid from '@components/blocks/CommonGrid'
import { x } from '@xstyled/styled-components'
import usePageGridLoading from '@utils/containers/usePageGridLoading'
import * as parcelsGridStore from '@store/parcelsGrid'

type Props = {
  expeditionId?: string
}

export const actionColumnDefinition = {
  flexBasis: 40,
  addRowNumbers: true,
}

export const Parcels: FC<Props> = ({ expeditionId }) => {
  const { data, gridIsLoading, error, allRowsCount, gridActions, gridSelectors } = usePageGridLoading<Parcel>({
    gridName: 'expeditionParcels',
    storeModule: parcelsGridStore,
    skip: !expeditionId,
    containerFilters: { expedition: { eq: expeditionId } },
  })

  return (
    <x.div mb="32px">
      <CommonGrid
        gridName="expeditionParcels"
        rowsData={data}
        actionColumnDefinition={actionColumnDefinition}
        columnsDefinitions={columnsDefinitions()}
        gridActions={gridActions}
        gridSelectors={gridSelectors}
        totalRowsCount={allRowsCount}
        withPresets={false}
        errorMessage={error}
        isLoading={gridIsLoading}
      />
    </x.div>
  )
}
