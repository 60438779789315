import { t } from '@lingui/macro'
import { type TabDefinition } from '@mailstep/design-system/ui/Blocks/Tabs'

type TabsForUpdateProps = {
  packagesCount: number
  adviceItemsCount: number
  defaultTab: number
}

export const tabsForCreate = (): TabDefinition[] => [
  {
    icon: 'info',
    label: t({ id: 'form.heading.expeditionDetail', message: 'Expedition details' }),
    default: true,
  },
]

export const tabsForUpdate = ({ packagesCount, adviceItemsCount, defaultTab }: TabsForUpdateProps): TabDefinition[] => [
  {
    label: t({ id: 'form.heading.expeditionDetail', message: 'Expedition details' }),
    default: defaultTab === 0 || undefined,
    badgeCount: adviceItemsCount,
  },
  {
    label: t({ id: 'form.packages.label', message: 'Packages' }),
    badgeCount: packagesCount,
    default: defaultTab === 1 || undefined,
  },
  {
    label: t({ id: 'form.heading.expeditionHistory', message: 'History' }),
    default: defaultTab === 2 || undefined,
  },
]
