import gql from 'graphql-tag'

export const expeditionDetailFields = `
  id
  user
  eshop
  warehouse
  wms
  dcCodeOfOrigin
  orderNumber
  note
  billingFirstName
  billingLastName
  billingDegree
  billingCompany
  billingStreet
  billingHouseNr
  billingZip
  billingCity
  billingCountry
  billingState
  billingEmail
  billingPhone
  billingRegistrationNumber
  billingVatNumber
  differentDeliveryAddress
  deliveryFirstName
  deliveryLastName
  deliveryDegree
  deliveryCompany
  deliveryStreet
  deliveryState
  deliveryHouseNr
  deliveryZip
  deliveryCity
  deliveryCountry
  deliveryEmail
  deliveryPhone
  requiredExpeditionDate
  carrier
  carrierService
  carrierPickupPlace
  externalCarrierPickupPlace
  carrierNote
  trackingNumber
  trackingUrl
  externalTrackingNumber
  partner
  packagesCount
  status
  value
  currency
  b2b
  fragile
  cod
  codValue
  codCurrency
  codVariableSymbol
  customerGroup
  sentAt
  packedAt
  deliveredAt
  eshopOrderDate
  createdAt
  changedAt
  items
  parcels
  wmsStatus
  invoiceUrl
  invoice
  invoiceOriginalName
  errors
  audits
  expeditedCompletely
  waitBeforeProcessing
  editBeforeProcessing
  countOfItems
  countOfSku
  ref1
  ref2
  ref3
  deliveryCost
  deliveryCostCurrency
  invoiceNumber
  foreignPrice
  removedVirtualProducts
  ignoreAddressValidation
  addressValidationExecuted
  hasDuplicatePositions
  carrierOptions
  services
`

const expeditionGetQuery = gql`
  query expeditionGet($id: String!) {
    expeditionGet(id: $id) @rest(type: "Expedition", method: "GET", path: "expedition/{args.id}") {
      ${expeditionDetailFields}
    }
  }
`

export default expeditionGetQuery
