import { t } from '@lingui/macro'
import { Country } from '@typings/entities/Country'
import { WarehouseAsNested } from '@typings/entities/Warehouse'
import { WmsAsNested } from '@typings/entities/Wms'
import { EshopApi, EshopAsNested } from '@typings/entities/Eshop'
import { ProductStockApi } from '@typings/entities/ProductStock'
import { ApiInitialError } from '@typings/entities/Error'
import { Parcel } from '@typings/entities/Parcel'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import {
  CarrierAsNested,
  CarrierPickupPlace,
  CarrierServiceAsNested,
  ExternalCarrierPickupPlace,
} from '@typings/entities/Carrier'
import { ProductApiRead } from '@typings/entities/Product'
import { AppearanceType } from '@constants/Appearance'
import { FlagAppearanceType } from '@constants/FlagAppearance'

export type BookStockAdvice = {
  id?: string
  stockAdviceItem: string
  quantity: number
  createdAt?: string
}

type BareExpeditionItem = {
  quantity: number
  lifo?: boolean
  lot?: string
  ref1?: string
  ref2?: string
  ref3?: string
  bookStockAdvices: BookStockAdvice[]
}

export type ExpeditionItemApiRead = BareExpeditionItem & {
  product: string
  id: string
  expedition: string
  position: number
  booked: number
  missing: number
  expedited?: number
  assignedLot?: string
}

export type DeletedItemApiRead = {
  productId: string
  quantity: number
}

export type ExpeditionItemNested = Omit<ExpeditionItemApiRead, 'product' | 'expedition'> & {
  product: ProductApiRead // TODO fixme, productAsNested
  expedition: ExpeditionAsNested
}

export type ExpeditionItemApiReadExtended = Omit<ExpeditionItemApiRead, 'product'> & {
  product: ProductApiRead
}

export type DeletedItemApiReadExtended = DeletedItemApiRead & {
  product: ProductApiRead
}

export type ExpeditionDetailItem = BareExpeditionItem & {
  id: string
  product: ProductApiRead
  book?: number
  expedited?: number
  stocksState: 'ready' | 'loading' | 'notReady' // notReady means warehouses are not selected
  availableTotal: number
  bookedAdviceTotal: number
  bookedStockAdvices?: BookStockAdvice[]
  bookAdviceTotal: number
}

export type ExpeditionDetailGridItem = ExpeditionDetailItem & {
  index: number
  reservationStatus: 'reserved' | 'paired' | 'partially' | 'notReserved'
  stockLots: ProductStockApi['lots']
  availableStock: number
  booked: number
  productValueCurrency?: string
  productValue?: string
}

export type ExpeditionDetailError = {
  [key: string]: ApiInitialError[] | string
}

export type ExpeditionDetailTouched = {
  [key: string]: boolean
}

export type ExpeditionAudit = {
  id: string
  createdAt: string
  statusTo: ExpeditionStatus
  updatedBy?: string
}

export type ExpeditionInvoice = {
  mimeType: string
  name: string
  originalName: string
  size: number
}

export const ExpeditionStatusTrans = {
  on_hold: () => t({ id: 'expeditionStatus.onHold', message: `Created / On hold` }),
  incorrect: () => t({ id: 'expeditionStatus.incorrect', message: `Incorrect` }),
  stock_ok: () => t({ id: 'expeditionStatus.stockOk', message: `To be confirmed` }),
  awaiting_reservation: () => t({ id: 'expeditionStatus.awaitingReservation', message: 'Awaiting reservation' }),
  waiting_for_goods: () => t({ id: 'expeditionStatus.waitingForGoods', message: `Waiting for goods` }),
  // in case of un comment do it please as well in src/pages/SharedModule/Expeditions/hooks.tsx
  // waiting_for_asn: () => t({ id: 'expeditionStatus.waitingForAsn', message: `Waiting for ASN` }),
  // ready_for_asn: () => t({ id: 'expeditionStatus.readyForAsn', message: `Ready for ASN` }),
  preallocated: () => t({ id: 'expeditionStatus.preallocated', message: `Preallocated` }),
  awaiting_processing: () => t({ id: 'expeditionStatus.awaitingProcessing', message: `Awaiting processing` }),
  take_out: () => t({ id: 'expeditionStatus.takeOut', message: `Take out` }),
  completion: () => t({ id: 'expeditionStatus.completion', message: `Completion` }),
  waiting_for_the_carrier: () => t({ id: 'expeditionStatus.waitingForTheCarrier', message: `Waiting for the carrier` }),
  carrier_picked_up: () => t({ id: 'expeditionStatus.carrierPickedUp', message: `Carrier picked up` }),
  delivered: () => t({ id: 'expeditionStatus.delivered', message: `Delivered` }),
  probably_delivered: () => t({ id: 'expeditionStatus.probablyDelivered', message: `Probably delivered` }),
  returned: () => t({ id: 'expeditionStatus.returned', message: `Returned` }),
  returning: () => t({ id: 'expeditionStatus.returning', message: `Returning` }),
  ready_to_takeover: () => t({ id: 'expeditionStatus.readyToTakeover', message: `Ready for takeover` }),
  waiting_for_cancel: () => t({ id: 'expeditionStatus.waitingForCancel', message: `Waiting for cancel` }),
  canceled: () => t({ id: 'expeditionStatus.canceled', message: `Canceled` }),
  // ---------------  currently unused  -------------
  // new: () => t({ id: 'expeditionStatus.new', message: `To process` }),
  // rejected_takeover: () => t({ id: 'expeditionStatus.rejectedTakeover', message: `Rejected takeover` }),
  // delivery_attempted: () => t({ id: 'expeditionStatus.deliveryAttempted', message: `Delivery attempted` }),
  // complaints_procedure: () => t({ id: 'expeditionStatus.complaintsProcedure', message: `Complains procedure` }),
  // canceled_delivery: () => t({ id: 'expeditionStatus.canceledDelivery', message: `Canceled delivery` }),
}

export const ExpeditionDetailStatusTrans = {
  waiting_for_asn: () => t({ id: 'expeditionStatus.waitingForAsn', message: `Waiting for ASN` }),
  ready_for_asn: () => t({ id: 'expeditionStatus.readyForAsn', message: `Ready for ASN` }),
  ...ExpeditionStatusTrans,
}

export const BaselinkerExpeditionStatuses = [
  'on_hold',
  'incorrect',
  'stock_ok',
  'waiting_for_goods',
  'awaiting_processing',
  'take_out',
  'completion',
  'waiting_for_the_carrier',
  'carrier_picked_up',
  'delivered',
  'probably_delivered',
  'returned',
  'returning',
  'ready_to_takeover',
  'waiting_for_cancel',
  'canceled',
]

export const StatusToAppearance: { [value in ExpeditionStatus]: AppearanceType } = {
  on_hold: 'concept',
  incorrect: 'error',
  stock_ok: 'info',
  waiting_for_goods: 'waiting',
  waiting_for_asn: 'asn',
  ready_for_asn: 'asn',
  awaiting_processing: 'processing',
  take_out: 'processing',
  preallocated: 'processing',
  completion: 'processing',
  waiting_for_the_carrier: 'success',
  carrier_picked_up: 'done',
  delivered: 'concept',
  probably_delivered: 'concept',
  returned: 'concept',
  returning: 'concept',
  ready_to_takeover: 'concept',
  waiting_for_cancel: 'concept',
  canceled: 'concept',
  new: 'concept',
  // rejected_takeover: 'concept',
  // delivery_attempted: 'concept',
  // complaints_procedure: 'concept',
  // canceled_delivery: 'concept',
}

export const FlagStatusToAppearance: { [value in ExpeditionStatus]: FlagAppearanceType } = {
  on_hold: 'neutralSubtle',
  incorrect: 'redSubtle',
  stock_ok: 'blueSubtle',
  waiting_for_goods: 'orangeBold',
  awaiting_processing: 'yellowBold',
  take_out: 'blueBold',
  completion: 'greenBold',
  waiting_for_the_carrier: 'orangeBold',
  carrier_picked_up: 'greenBold',
  delivered: 'greenSubtle',
  probably_delivered: 'purpleSubtle',
  returned: 'yellowSubtle',
  returning: 'yellowSubtle',
  ready_to_takeover: 'blueSubtle',
  waiting_for_cancel: 'orangeSubtle',
  canceled: 'redSubtle',
  preallocated: 'tealBold',
  // new: 'tealSubtle',
  // rejected_takeover: 'redSubtle',
  // delivery_attempted: 'neutralBold',
  // complaints_procedure: 'pinkSubtle',
  // canceled_delivery: 'redSubtle',
  waiting_for_asn: 'neutralBold',
  ready_for_asn: 'tealSubtle',
  awaiting_reservation: 'purpleBold',
}

export const StatusToIcon: { [value in ExpeditionStatus]: string } = {
  canceled: 'letterX',
  // canceled_delivery: 'letterX',
  carrier_picked_up: 'deliveryTruck',
  completion: 'rotate',
  // complaints_procedure: 'undo',
  delivered: 'tick',
  // delivery_attempted: 'standingUpMan',
  incorrect: 'warning',
  // new: 'plus',
  on_hold: 'plus',
  probably_delivered: 'tick',
  ready_to_takeover: 'standingUpMan',
  // rejected_takeover: 'standingUpMan',
  returned: 'undo',
  returning: 'undo',
  stock_ok: 'plus',
  take_out: 'rotate',
  awaiting_processing: 'timeLeft',
  waiting_for_cancel: 'letterX',
  waiting_for_goods: 'timeLeft',
  waiting_for_the_carrier: 'timeLeft',
  preallocated: 'plus',
  waiting_for_asn: 'rotate',
  ready_for_asn: 'deliveryTruck',
  awaiting_reservation: 'timeLeft',
}

export type ExpeditionStatus = keyof typeof ExpeditionStatusTrans

type BareExpedition = {
  id: string
  orderNumber: string
  note?: string
  deliveryFirstName: string
  deliveryLastName: string
  deliveryDegree?: string
  deliveryCompany?: string
  deliveryStreet: string
  deliveryState: string
  deliveryHouseNr: string
  deliveryZip: string
  deliveryCity: string
  deliveryEmail: string
  deliveryPhone: string
  differentDeliveryAddress: boolean
  billingFirstName: string
  billingLastName: string
  billingDegree?: string
  billingCompany?: string
  billingStreet: string
  billingHouseNr: string
  billingZip: string
  billingCity: string
  billingState: string
  billingEmail: string
  billingPhone: string
  billingRegistrationNumber: string // IČO
  billingVatNumber: string
  carrierNote?: string
  trackingNumber?: string
  trackingUrl?: string
  genericTrackingUrl?: string | null
  externalTrackingNumber?: string
  packagesCount: number
  partner: string
  status: ExpeditionStatus
  value: string
  currency: string
  b2b: boolean
  fragile: boolean
  cod: boolean
  codValue: string
  codCurrency: string
  codVariableSymbol: string
  customerGroup?: string
  sentAt?: string
  packedAt?: string
  deliveredAt?: string
  createdAt: string
  changedAt: string
  invoiceUrl?: string
  invoiceOriginalName?: string
  invoice: ExpeditionInvoice
  withComplaint?: boolean
  expeditedCompletely: boolean | null
  ignoreAddressValidation?: boolean
}

type ExpeditionNestedProps = BareExpedition & {
  user?: {
    id: string
    firstName: string
    lastName: string
  }
  eshop: EshopAsNested
  warehouse: WarehouseAsNested
  wms: WmsAsNested
  deliveryCountry: Country
  billingCountry: Country
  carrier?: CarrierAsNested
  carrierService?: CarrierServiceAsNested
  carrierPickupPlace?: CarrierPickupPlace
  externalCarrierPickupPlace?: ExternalCarrierPickupPlace
}

type ExpeditionSimpleProps = BareExpedition & {
  user?: string
  eshop: string
  warehouse: string
  wms: string
  deliveryCountry: string
  billingCountry: string
  carrier?: string
  carrierService?: string
  carrierPickupPlace?: string
  externalCarrierPickupPlace?: string
}

export type ExpeditionAsNested = ExpeditionSimpleProps & {
  requiredExpeditionDate: string
  eshopOrderDate?: string
}

export type ExpeditionApi = ExpeditionAsNested & {
  items: ExpeditionItemApiRead
  removedVirtualProducts: DeletedItemApiRead[]
  carrierPickupPlace?: string
  externalCarrierPickupPlace?: string
  errors?: ApiInitialError[]
  waitBeforeProcessing?: boolean
  editBeforeProcessing?: boolean
  addressValidationExecuted?: boolean
  hasDuplicatePositions?: boolean
}

export type ExpeditionApiExtended = ExpeditionAsNested & {
  items: ExpeditionItemApiReadExtended[]
  removedVirtualProducts: DeletedItemApiReadExtended[]
  errors?: ApiInitialError[]
  waitBeforeProcessing?: boolean
  editBeforeProcessing?: boolean
  eshopObj: EshopApi
}

export type Expedition = ExpeditionApi

export type ExpeditionDetail = ExpeditionSimpleProps & {
  originalId?: string // for cloned expedition
  requiredExpeditionDate: Date
  eshopOrderDate?: Date
  deliveryPdfFile?: Blob | { name: string }
  items: ExpeditionDetailItem[]
  parcels: Parcel[]
  virtualItems?: DeletedItemApiReadExtended[]
  audits: ExpeditionAudit[]
  waitBeforeProcessing?: boolean
  editBeforeProcessing?: boolean
  eshopObj?: EshopApi
  bookedData?: {
    items: {
      [productId: string]: {
        [lot: string]: number
      }
    }
  }
  foreignPrice: {
    amount: number
    currency: string
  }
  carrierOptions: {
    recipientIdentificationNumber: string
  }
  countOfItems: number
  countOfSku: number
}

export type ExpeditionNested = ExpeditionNestedProps & {
  requiredExpeditionDate: string
  eshopOrderDate?: string
  waitBeforeProcessing?: boolean
  editBeforeProcessing?: boolean
}

export type ExpeditionDetailWrite = ExpeditionSimpleProps & {
  requiredExpeditionDate: string
  eshopOrderDate?: string
  items: (BareExpeditionItem & {
    product: string
    book: number
  })[]
}

export const ExpeditedStatuses = [
  'completion',
  'waiting_for_the_carrier',
  'carrier_picked_up',
  'delivered',
  'probably_delivered',
  'returned',
  'returning',
  'ready_to_takeover',
  // 'rejected_takeover',
  // 'delivery_attempted',
  // 'complaints_procedure',
  // 'canceled_delivery',
]

export const ExpeditionFieldNameTrans = {
  eshop: () => t({ id: 'expeditionFieldName.eshop', message: `Eshop` }),
  warehouse: () => t({ id: 'form.warehouse.label', message: `Virtual warehouse` }),
  wms: () => t({ id: 'form.wms.label', message: `Physical warehouse` }),
  orderNumber: () => t({ id: 'form.orderNumber.label', message: `Order number` }),
  billingFirstName: () => t({ id: 'expeditionFieldName.billingFirstName', message: `Billing address - first name` }),
  billingLastName: () => t({ id: 'expeditionFieldName.billingLastName', message: `Billing address - last name` }),
  billingDegree: () => t({ id: 'expeditionFieldName.billingDegree', message: `Billing address - degree` }),
  billingCompany: () => t({ id: 'expeditionFieldName.billingCompany', message: `Billing address - company` }),
  billingStreet: () => t({ id: 'expeditionFieldName.billingStreet', message: `Billing address - street` }),
  billingHouseNr: () => t({ id: 'expeditionFieldName.billingHouseNr', message: `Billing address - house nr.` }),
  billingZip: () => t({ id: 'expeditionFieldName.billingZip', message: `Billing address - ZIP` }),
  billingCity: () => t({ id: 'expeditionFieldName.billingCity', message: `Billing address - city` }),
  billingCountry: () => t({ id: 'expeditionFieldName.billingCountry', message: `Billing address - country` }),
  billingState: () => t({ id: 'expeditionFieldName.billingState', message: `Billing address - state` }),
  billingEmail: () => t({ id: 'expeditionFieldName.billingEmail', message: `Billing address - email address` }),
  billingPhone: () => t({ id: 'expeditionFieldName.billingPhone', message: `Billing address - phone number` }),
  billingRegistrationNumber: () =>
    t({
      id: 'expeditionFieldName.billingRegistrationNumber',
      message: `Billing address - registration number`,
    }),
  billingVatNumber: () => t({ id: 'expeditionFieldName.billingVatNumber', message: `Billing address - VAT` }),
  deliveryFirstName: () => t({ id: 'expeditionFieldName.deliveryFirstName', message: `Delivery address - first name` }),
  deliveryLastName: () => t({ id: 'expeditionFieldName.deliveryLastName', message: `Delivery address - last name` }),
  deliveryDegree: () => t({ id: 'expeditionFieldName.deliveryDegree', message: `Delivery address - degree` }),
  deliveryCompany: () => t({ id: 'expeditionFieldName.deliveryCompany', message: `Delivery address - company` }),
  deliveryStreet: () => t({ id: 'expeditionFieldName.deliveryStreet', message: `Delivery address - street` }),
  deliveryState: () => t({ id: 'expeditionFieldName.deliveryState', message: `Delivery address - state` }),
  deliveryHouseNr: () => t({ id: 'expeditionFieldName.deliveryHouseNr', message: `Delivery address - house nr.` }),
  deliveryZip: () => t({ id: 'expeditionFieldName.deliveryZip', message: `Delivery address - ZIP` }),
  deliveryCity: () => t({ id: 'expeditionFieldName.deliveryCity', message: `Delivery address - city` }),
  deliveryCountry: () => t({ id: 'expeditionFieldName.deliveryCountry', message: `Delivery address - country` }),
  deliveryEmail: () => t({ id: 'expeditionFieldName.deliveryEmail', message: `Delivery address - email address` }),
  deliveryPhone: () => t({ id: 'expeditionFieldName.deliveryPhone', message: `Delivery address - phone number` }),
  requiredExpeditionDate: () => t({ id: 'form.requiredExpeditionDate.label', message: `Required date of expedition` }),
  carrier: () => t({ id: 'form.carrier.label', message: `Shipping carrier` }),
  carrierService: () => t({ id: 'form.carrierService.label', message: `Shipping service` }),
  carrierPickupPlace: () => t({ id: 'form.carrierPickupPlace.label', message: `Pickup place` }),
  externalCarrierPickupPlace: () => t({ id: 'form.externalCarrierPickupPlace.label', message: `External pickup place` }),
  partner: () => t({ id: 'form.partner.label', message: `Partner` }),
  value: () => t({ id: 'form.packageValue.label', message: `Package value` }),
  currency: () => t({ id: 'expeditionFieldName.currency', message: `Currency` }),
  codValue: () => t({ id: 'form.codValue.label', message: `COD value` }),
  codCurrency: () => t({ id: 'expeditionFieldName.currency', message: `Currency` }),
  codVariableSymbol: () => t({ id: 'form.codVariableSymbol.label', message: `Variable symbol` }),
  customerGroup: () => t({ id: 'form.customerGroup.label', message: `Customer group` }),
  eshopOrderDate: () => t({ id: 'form.eshopOrderDate.label', message: `Eshop order date` }),
  items: () => t({ id: 'form.expeditionItems.heading', message: `Outbound items` }),
}

// todo rewrite as other Trans objects
export const MetaLotOptionsTrans = (): SelectOption[] => [
  { value: '__oldest', label: t({ id: 'lotOptions.oldest', message: `Oldest` }) },
  { value: '__newest', label: t({ id: 'lotOptions.newest', message: `Newest` }) },
]

export const EditableStatuses = [
  'on_hold',
  'new',
  'waiting_for_goods',
  'incorrect',
  'waiting_for_asn',
  'ready_for_asn',
  'awaiting_reservation',
]

export type ExpeditionToWmsResult = {
  id: string
  reason: string
}

export type ExpeditionServices = {
  id: string
  code: string
  name: string
  description: string
  createdAt: string
  active: boolean
  organisations: string | string[]
  changedAt: string
  changedBy: string
}
